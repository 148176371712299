export default class Debouncer {
    private _debounceTime: number;
    private _debounce: number | null = null;

    get active() {
        return this._debounce != null;
    }

    constructor(pDebounce = 500) {
        this._debounceTime = pDebounce;
    }

    clear() {
        if (this._debounce) { window.clearTimeout(this._debounce); }
        this._debounce = null;
    }

    run(pFunction: () => void) {
        this.clear();
        this._debounce = window.setTimeout(() => {
            pFunction();
            this._debounce = null;
        }, this._debounceTime);
    }
}
